import { CustomEdit } from '../../custom';
import Form from './form';

export default function Editteams(props) {
  return (
    <CustomEdit {...props} title="Editar equipe">
      <Form />
    </CustomEdit>
  );
}
