import { CustomCreate } from '../../custom';
import Form from './form';

export default function CreateSalaryOption(props) {
  return (
    <CustomCreate {...props} title="Nova opção">
      <Form />
    </CustomCreate>
  );
}
