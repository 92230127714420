import icon from '@material-ui/icons/Assignment';
import list from './list';

export { list };

const companyReports = {
  name: 'company-reports',
  options: { label: 'Relatórios' },
  icon,
  list
};

export default companyReports;
