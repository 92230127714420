import { CustomEdit } from '../../custom';
import Form from './form';

export default function EditCompanies(props) {
  return (
    <CustomEdit {...props} title="Editar empresa">
      <Form />
    </CustomEdit>
  );
}
