import icon from '@material-ui/icons/Business';
import list from './list';

export { list };

const company = {
  name: 'import-users',
  options: { label: 'Importar voluntários' },
  icon,
  list
};

export default company;
