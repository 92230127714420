import icon from '@material-ui/icons/Security';
import create from './create';

export { create };

const passwordResets = {
  name: 'password-resets',
  options: { label: 'Resetar senha' },
  icon,
  list: create
};

export default passwordResets;
