import IconButton from '@material-ui/core/IconButton';
import MuiToolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import {
  ArrayField,
  ChipField, DateField, DeleteButton, ImageField, NumberField, ReferenceArrayField, ReferenceField,
  Show,
  SimpleShowLayout, SingleFieldList, TextField, useShowController
} from 'react-admin';
import AdminTitle from '../../custom/AdminTitle';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 40
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em'
  },
  form: {
    [theme.breakpoints.up('xs')]: {
      width: 400
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: -30
    }
  },
  inlineField: {
    display: 'inline-block',
    width: '50%'
  }
}));

export default function ShowNotifications({ onCancel, ...props }) {
  const classes = useStyles();
  const controllerProps = useShowController(props);

  
  if (!controllerProps.record) {
    return null;
  }

  const { basePath, record, resource } = controllerProps;

  return (
    <>
      <AdminTitle />
      <Show {...props}>
        <div className={classes.root}>
          <div className={classes.title}>
            <Typography variant="h6">{record.name}</Typography>
            <IconButton onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </div>
          <SimpleShowLayout
            
            className={classes.form}
          >
            <ImageField source="image.url" label="Imagem" />
            <ReferenceField source="team" reference="teams" label="Equipe">
              <TextField source="name" />
            </ReferenceField>

            <ReferenceField
              source="createdBy"
              reference="companyUsers"
              label="Cadastrada por"
            >
              <TextField source="name" />
            </ReferenceField>

            <DateField showTime label="Cadastrada em" source="createdAt" />

            <DateField showTime label="Data inicial" source="startDate" />

            <DateField label="Data final" source="endDate" />

            <TextField label="Local" source="place" />

            {record.finished ? (
              <TextField label="Resumo da ação" source="summary" />
            ) : (
              <TextField label="Descrição" source="description" />
            )}

            {record.finished && (
              <NumberField label="Beneficiados" source="benefitedQuantity" />
            )}

            {record.finished && (
              <ArrayField source="images">
                <SingleFieldList linkType={false}>
                  <ImageField source="url" label="Imagem" />
                </SingleFieldList>
              </ArrayField>
            )}

            {record.finished && (
              <ReferenceArrayField
                label="Participantes"
                source="participants"
                reference="companyUsers"
              >
                <SingleFieldList>
                  <ChipField source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
            )}
            
            <MuiToolbar className={classes.root}>
              <DeleteButton basePath={basePath} record={record} resource={resource} />
            </MuiToolbar>
          </SimpleShowLayout>
        </div>
      </Show>
    </>
  );
}
