import React from 'react';
import EditorJs from 'react-editor-js';
import { EDITOR_TOOLS } from './plugins';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Labeled, FormDataConsumer } from 'react-admin';
import get from 'lodash/get';
import { useInput } from 'ra-core';

const useStyles = makeStyles(theme => ({
  root: { padding: '0 12px 12px' },
  upload: {
    '& .previews': { display: 'none' }
  },
  progress: { width: '100%' }
}));

export default function CustomEditor(props) {
  const {
    mimeTypes,
    record,
    validate,
    source,
    className,
    helperText,
    ...rest
  } = props;

  const classes = useStyles(props);

  const {
    input: { onChange },
    meta,
    isRequired
  } = useInput(props);

  return (
    <FormControl
      fullWidth
      variant="filled"
      margin="dense"
      className={classnames(
        classes.root,
        className,
        'MuiFilledInput-root',
        'MuiFilledInput-underline'
      )}
    >
      <Labeled
        {...rest}
        record={record}
        source={source}
        isRequired={isRequired}
        meta={meta}
      />
      <FormDataConsumer>
        {({ formData, record, ...rest }) => {
          const value = get(formData, source);
          return (
            <EditorJs
              tools={EDITOR_TOOLS}
              data={value}
              onChange={(e, value) => onChange(value)}
            />
          );
        }}
      </FormDataConsumer>
    </FormControl>
  );
}
