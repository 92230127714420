import {
  BooleanInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required
} from 'react-admin';
// import MaskedInput from 'react-text-mask';
import { UploadInput } from '../../custom';

export const CHOICES = [
  { id: 'AUDIENCE', name: 'Público alvo' },
  { id: 'ODS', name: 'ODS' },
  { id: 'SKILL', name: 'Competências' },
]

export default function FormActivityCategory(props) {
  return (
    <SimpleForm {...props} redirect="list">
      <TextInput
        fullWidth
        label="Nome"
        source="name"
        validate={[required()]}
      />

      <SelectInput
        choices={CHOICES}
        label="Tipo"
        source="type"
      />

      <UploadInput
        fullWidth
        label="Ícone"
        source="icon"
        helperText="Enviar arquivo em formato SVG."
        mimeTypes={['.svg']}
      />

      <BooleanInput label="Ativo" source="active" />
    </SimpleForm>
  );
}
