import React from 'react';
import get from 'lodash/get';
import numeral from 'numeral';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  ReportRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Poppins',
    padding: 40,
    textAlign: 'center'
  },
  CircleRoot: props => ({
    alignItems: 'center',
    backgroundColor: props.color || theme.palette.primary.main,
    borderRadius: '50%',
    display: 'flex',
    height: 114,
    justifyContent: 'center',
    width: 114
  }),
  CircleCenter: {
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    height: '90%',
    justifyContent: 'center',
    width: '90%'
  },
  Icon: {
    objectFit: 'contain',
    objectPosition: 'bottom center',
    width: '50%'
  },
  Label: {
    fontWeight: '700',
    marginBottom: 0,
    marginTop: 10
  }
}));

export default function ReportField({ color, icon, label, record, source }) {
  const classes = useStyles({ color });
  const value = get(record, source);

  return (
    <div className={classes.ReportRoot}>
      <div className={classes.CircleRoot}>
        <div className={classes.CircleCenter}>
          <img className={classes.Icon} src={icon} alt={label} />
          <p className={classes.Label}>{numeral(value).format()}</p>
        </div>
      </div>
      <p>{label}</p>
    </div>
  );
}
