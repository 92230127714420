import { Create } from 'react-admin';
import { formWrapper } from '.';
import AdminTitle from './AdminTitle';

export default function CustomCreate(props) {
  return (
    <>
      <AdminTitle />
      {formWrapper({ ...props })(Create)}
    </>
  );
}
