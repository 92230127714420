import { SimpleForm, TextInput, required } from 'react-admin';

export default function FormSalaryOption(props) {
  return (
    <SimpleForm {...props} redirect="list">
      <TextInput fullWidth label="Nome" source="name" validate={[required()]} />
      <TextInput 
        source="hourlyRate"   
        label="Valor da hora"
        validate={[required()]} 
      />
    </SimpleForm>
  );
}
