import {
  Avatar,
  Box,
  CardHeader,
  Divider,
  useMediaQuery
} from '@material-ui/core';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import WebIcon from '@material-ui/icons/Web';
import { useCallback /*, useState */ } from 'react';
import { MenuItemLink, useGetIdentity, usePermissions } from 'react-admin';
import { useSelector } from 'react-redux';
// import ListAltIcon from '@material-ui/icons/ListAlt';
// import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';

// import Submenu from './Submenu';
import { resources } from '../components';
import { CompanySelector } from '../components/custom';

export default function Menu({ onMenuClick, dense, logout }) {
  // const [state, setState] = useState({
  //   // menuPublicPosts: true
  // });

  const { permissions } = usePermissions();

  const { identity: user } = useGetIdentity();

  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const open = useSelector(state => state.admin.ui.sidebarOpen);

  useSelector(state => state.theme); // force rerender on theme change

  // const handleToggle = useCallback(menu => {
  //   setState(state => ({ ...state, [menu]: !state[menu] }));
  // }, []);

  // const getSubmenuProps = useCallback(
  //   ({ resource, toggle }) => {
  //     const Icon = resources[resource].icon;
  //     return {
  //       handleToggle: () => handleToggle(toggle),
  //       name: resources[resource].options.label,
  //       icon: <Icon />,
  //       sidebarIsOpen: open,
  //       dense
  //     };
  //   },
  //   [handleToggle, open, dense]
  // );

  const getMenuItemLinkProps = useCallback(
    ({ resource }) => {
      const Icon = resources[resource].icon;
      return {
        to: `/${resource}`,
        primaryText: resources[resource].options.label,
        leftIcon: <Icon />,
        onClick: onMenuClick,
        sidebarIsOpen: open,
        dense
      };
    },
    [dense, onMenuClick, open]
  );

  return (
    <div>
      {permissions?.includes('SUPERADMIN') && (
        <>
          <CardHeader
            avatar={
              <Avatar>
                <WebIcon />
              </Avatar>
            }
            style={{ paddingLeft: '4px' }}
            title="Ben"
            titleTypographyProps={{ variant: 'button' }}
          />

          <MenuItemLink {...getMenuItemLinkProps({ resource: 'adminUsers' })} />

          <MenuItemLink {...getMenuItemLinkProps({ resource: 'companies' })} />

          <MenuItemLink
            {...getMenuItemLinkProps({ resource: 'publicPosts' })}
          />

          <MenuItemLink
            {...getMenuItemLinkProps({ resource: 'notifications' })}
          />

          <MenuItemLink {...getMenuItemLinkProps({ resource: 'reports' })} />

          <MenuItemLink {...getMenuItemLinkProps({ resource: 'salaryOptions' })} to="/salary-options" />

          <MenuItemLink {...getMenuItemLinkProps({ resource: 'settings' })} />

          <Box m={2} y={1}>
            <Divider light variant="middle" />
          </Box>

          <CardHeader
            avatar={
              <Avatar>
                <PhoneIphoneIcon />
              </Avatar>
            }
            style={{ paddingLeft: '4px' }}
            title="Empresa"
            titleTypographyProps={{ variant: 'button' }}
          />
          <CompanySelector />
        </>
      )}

      {user?.company && (
        <>
          <MenuItemLink {...getMenuItemLinkProps({ resource: 'company' })} />

          <MenuItemLink
            {...getMenuItemLinkProps({ resource: 'companyPosts' })} to="/company-posts"
          />

          <MenuItemLink
            {...getMenuItemLinkProps({ resource: 'companyUsers' })}
          />

          <MenuItemLink
            {...getMenuItemLinkProps({ resource: 'registrations' })}
          />

          <MenuItemLink
            {...getMenuItemLinkProps({ resource: 'passwordResets' })} to="password-resets"
          />

          <MenuItemLink {...getMenuItemLinkProps({ resource: 'teams' })} />

          <MenuItemLink {...getMenuItemLinkProps({ resource: 'activities' })} />

          <MenuItemLink
            {...getMenuItemLinkProps({ resource: 'companyReports' })}
            to="/company-reports"
          />
        </>
      )}

      {/* <Submenu
        {...getSubmenuProps({
          resource: 'publicPosts',
          toggle: 'menuPublicPosts'
        })}
        isOpen={state.menuPublicPosts}
      >
        
        <MenuItemLink
          {...getMenuItemLinkProps({ resource: 'publicPostsCategories' })}
        />
      </Submenu> */}

      {isXSmall && logout}
    </div>
  );
}
