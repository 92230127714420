import icon from '@material-ui/icons/Description';
import create from './create';
import edit from './edit';
import list from './list';

export { create, edit, list };

const publicposts = {
  name: 'publicPosts',
  options: { label: 'Notícias públicas' },
  icon,
  create,
  edit,
  list
};

export default publicposts;
