import icon from '@material-ui/icons/Settings';
import list from './list';

export { list };

const company = {
  name: 'settings',
  options: { label: 'Configurações' },
  icon,
  list
};

export default company;
