import React from 'react';
import { List } from 'react-admin';
import AdminTitle from './AdminTitle';

export default function CustomList({
  children,
  options: { label },
  sort: { field, order } = {},
  ...props
}) {
  return (
    <>
      <AdminTitle />
      <List
        exporter={false}
        perPage={25}
        sort={{ field, order: order || 'DESC' }}
        title={label}
        {...props}
      >
        {children}
      </List>
    </>
  );
}
