import { Create, PasswordInput, ReferenceInput, SaveButton, SelectInput, SimpleForm, Toolbar, minLength, required, useNotify, useRedirect } from 'react-admin';
import AdminTitle from '../../custom/AdminTitle';

function PasswordResetToolbar(props) {
  const notify = useNotify();
  const redirect = useRedirect();
  return (
    <Toolbar {...props}>
      <SaveButton 
        disabled={props.pristine || props.validating} 
        onSuccess={() => { 
          notify('Senha alterada com sucesso!', 'info');
          redirect(false);
        }}
      />
    </Toolbar>
  );
}



export default function CreatePasswordReset(props) {
  return (
    <>
      <AdminTitle />
      <Create 
        {...props} 
        title="Resetar senha" 
      >
        <SimpleForm toolbar={<PasswordResetToolbar />}>
          <ReferenceInput 
            label="Usuário" 
            source="user_id" 
            reference="companyUsers"
            sort={{ field: 'name', order: 'ASC' }}
            perPage={-1}
            filter={{ active: true }}
            validate={[required()]}
          >
            <SelectInput />
          </ReferenceInput>

          <PasswordInput
            autoComplete="new-password"
            label="Nova senha"
            source="password"
            validate={[required(), minLength(6)]}
          />

        </SimpleForm>
      </Create>
    </>
  );
}
