import {
  BooleanInput,
  Datagrid,
  DateField,
  DateInput
  // ReferenceInput,
  // SelectInput,
  ,
  Filter,
  TextField,
  TextInput
} from 'react-admin';
import { CustomList, StatusField, activeRowStyle } from '../../custom';

function dateFormatter(v) {
  if (v) {
    const [d, m, y] = new Date(v)
      .toLocaleDateString('pt-BR', { timeZone: 'UTC' })
      .split('/');
    return `${y.padStart(4, '0')}-${m.padStart(2, '0')}-${d.padStart(2, '0')}`;
  } else {
    return;
  }
}

function Filters(props) {
  return (
    <Filter {...props}>
      <TextInput fullWidth label="Buscar por nome" source="name[$regex]" />
      {/* <ReferenceInput
        label="Categoria"
        source="category"
        reference="publicPostsCategories"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{
          $limit: -1
        }}
      >
        <SelectInput />
      </ReferenceInput> */}
      <DateInput format={dateFormatter} label="Data" source="date" />
      <BooleanInput label="Ativo" source="active" />
    </Filter>
  );
}

export default function ListPublicPosts(props) {
  return (
    <CustomList
      {...props}
      filters={<Filters />}
      title={props.options.label}
      sort={{ field: 'date', order: 'DESC' }}
    >
      <Datagrid rowStyle={activeRowStyle} rowClick="edit">
        <DateField options={{ timeZone: 'UTC' }} source="date" label="Data" />
        <TextField label="Nome" source="name" />
        <StatusField label="Ativo" />
      </Datagrid>
    </CustomList>
  );
}
