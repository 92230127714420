import icon from '@material-ui/icons/Money';
import create from './create';
import edit from './edit';
import list from './list';

export { create, edit, list };

const salaryOptions = {
  name: 'salary-options',
  options: { label: 'Média salarial' },
  icon,
  create,
  edit,
  list
};

export default salaryOptions;
