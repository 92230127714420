import { useCallback } from 'react';
import {
  Button,
  Create,
  SaveButton,
  SimpleForm,
  Toolbar,
  TopToolbar,
  required,
  useNotify
} from 'react-admin';
import { useForm } from 'react-final-form';
import DownloadIcon from '@material-ui/icons/GetApp';
import UploadIcon from '@material-ui/icons/Publish';
import { UploadInput } from '../../custom';
import AdminTitle from '../../custom/AdminTitle';

function CreateImportUsersActions(props) {
  return (
    <TopToolbar {...props}>
      <Button
        component="a"
        download
        href="/template.csv"
        label="Baixar arquivo de exemplo"
      >
        <DownloadIcon />
      </Button>
    </TopToolbar>
  );
}

function CreateImportUsersToolbar(props) {
  const form = useForm();
  const notify = useNotify();
  const onFailure = useCallback(
    error => {
      notify(typeof error === 'string' ? error : error.message, 'warning');
      form.restart();
    },
    [form, notify]
  );
  const onSuccess = useCallback(() => {
    notify('ra.notification.created', 'info');
    form.restart();
  }, [form, notify]);
  return (
    <Toolbar {...props}>
      <SaveButton
        disabled={props.pristine || props.validating}
        icon={<UploadIcon />}
        label="Importar"
        onFailure={onFailure}
        onSuccess={onSuccess}
      />
    </Toolbar>
  );
}

export default function CreateImportUsers(props) {
  return (
    <>
      <AdminTitle />
      <Create
        {...props}
        actions={<CreateImportUsersActions />}
        title="Importar voluntários"
      >
        <SimpleForm
          redirect="/companyUsers"
          toolbar={<CreateImportUsersToolbar />}
        >
          <UploadInput
            fullWidth
            helperText={`Enviar arquivo em formato XLSX ou CSV. \n A tabela não deve ter cabeçalho e deve conter os dados na ordem: Nome, Email, Equipe. \n Nenhuma alteração será feita caso alguma das equipes referenciadas no documento não exista. \n Caso haja algum voluntário que já esteja cadastrado na plataforma, ele será ignorado, mas os outros serão inseridos normalmente.`}
            label="Planilha"
            mimeTypes="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.oasis.opendocument.spreadsheet"
            source="file"
            validate={[required()]}
          />
        </SimpleForm>
      </Create>
    </>
  );
}
