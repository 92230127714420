import {
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required
} from 'react-admin';
import { UploadImageInput, UploadInput } from '../../custom';
import AdminTitle from '../../custom/AdminTitle';

function EditCompanyToolbar(props) {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine || props.validating} />
    </Toolbar>
  );
}

export default function EditCompany(props) {
  return (
    <>
      <AdminTitle />
      <Edit {...props} id="me" title="Editar empresa">
        <SimpleForm toolbar={<EditCompanyToolbar />}>
          <TextInput
            fullWidth
            label="Nome Fantasia"
            source="name"
            validate={[required()]}
          />

          <TextInput
            autoComplete="off"
            fullWidth
            label="CNPJ"
            source="cnpj"
            validate={[required()]}
            // InputProps={{ inputComponent: MaskedInput, mask: [/d/] }}
          />

          <TextInput
            autoComplete="off"
            fullWidth
            label="Endereço"
            source="address"
            validate={[required()]}
          />

          <UploadImageInput
            fullWidth
            label="Logo"
            source="logo"
            mimeTypes={['image/png']}
            maxSize={[300, 300]}
            helperText="É possível enviar imagens no formato PNG com dimensões recomendadas de 300x300px."
          />

          <UploadImageInput
            fullWidth
            label="Logo para o certificado de horas voluntárias"
            source="logoCertificate"
            mimeTypes={['image/png']}
            maxSize={[300, 300]}
            helperText="É possível enviar imagens no formato PNG com dimensões recomendadas de 300x300px."
          />

          <UploadImageInput
            fullWidth
            label="Imagem de fundo para o certificado de horas voluntárias"
            source="imageCertificate"
            mimeTypes={['image/png']}
            helperText="É possível enviar imagens no formato PNG com dimensões recomendadas de 3508x2480px."
          />

          <UploadInput
            fullWidth
            label="Termos de voluntariado"
            source="termsOfService"
            helperText="Enviar arquivo em formato PDF."
            mimeTypes="application/pdf"
          />
        </SimpleForm>
      </Edit>
    </>
  );
}
