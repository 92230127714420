import {
  Datagrid,
  TextField
} from 'react-admin';
import { CustomList } from '../../custom';


export default function ListSalaryOptions(props) {
  return (
    <CustomList
      {...props}
      title={props.options.label}
      sort={{ field: '_id', order: 'ASC' }}
    >
      <Datagrid rowClick="edit">
        <TextField label="Nome" source="name" />
        <TextField label="Valor hora" source="hourlyRate" />
      </Datagrid>
    </CustomList>
  );
}
