import activities from './activities';
import activityCategories from './activityCategories';
import adminUsers from './adminUsers';
import companies from './companies';
import company from './company';
import companyPosts from './companyPosts';
import companyReports from './companyReports';
import companyUsers from './companyUsers';
import importUsers from './importUsers';
import notifications from './notifications';
import publicPosts from './publicPosts';
import salaryOptions from './salaryOptions';
// import publicPostsCategories from './publicPostsCategories';
import passwordResets from './passwordResets';
import registrations from './registrations';
import reports from './reports';
import settings from './settings';
import teams from './teams';

const resources = {
  activities,
  adminUsers,
  companies,
  company,
  companyReports,
  companyUsers,
  importUsers,
  notifications,
  publicPosts,
  companyPosts,
  activityCategories,
  // publicPostsCategories,
  salaryOptions,
  reports,
  settings,
  teams,
  registrations,
  passwordResets
};

export default resources;
