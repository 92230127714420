import ReactDOM from 'react-dom';
import numeral from 'numeral';
import App from './App';

import reportWebVitals from './reportWebVitals';

numeral.register('locale', 'pt-br', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: function (number) {
    return `${number}º`;
  },
  currency: {
    symbol: 'R$'
  }
});

numeral.locale('pt-br');

ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();
