import Chip from '@material-ui/core/Chip';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import React from 'react';

export default function StatusField({
  record,
  source = 'active',
  values = ['Ativo', 'Desativado'],
  invert = false,
  ...props
}) {
  if (!record) {
    return null;
  }

  const isChecked = invert ? !record[source] : record[source];

  return (
    <Chip
      key={record.id}
      label={isChecked ? values[0] : values[1]}
      style={{
        backgroundColor: isChecked ? green[500] : red[500],
        color: '#ffffff'
      }}
    />
  );
}
