import { useCallback } from 'react';
import {
  Fab,
  FormHelperText,
  Tooltip,
  Grid,
  FormControl
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import classnames from 'classnames';
import {
  Labeled,
  ImageField,
  FormDataConsumer,
  InputHelperText
} from 'react-admin';
import { useInput } from 'ra-core';
import get from 'lodash/get';
import tusUploader from '../tusUploader';
import {
  Uppload,
  Local,
  Preview,
  Crop,
  Blur,
  Brightness,
  Camera,
  Contrast,
  Grayscale,
  HueRotate,
  Invert,
  Saturate,
  Sepia,
  Unsplash
} from 'uppload';

import './style.css';
import ptBR from './ptBR';

const defaultEffects = [
  'crop',
  'camera',
  'unsplash',
  'blur',
  'brightness',
  'constrast',
  'grayscale',
  'hueRotate',
  'invert',
  'saturate',
  'sepia'
];

const unsplashApiKey = 'FXBjPIDCQViectqJ8qA3v57CKtpzAAO9BwRjYe_xCjs';

const useStyles = makeStyles(theme => ({
  root: { boxSizing: 'border-box', padding: '0 12px 12px' }
}));

function createUppload({
  updateFieldCallback,
  upploadOptions = {},
  services = ['local'],
  effects = defaultEffects,
  mimeTypes = ['image/png', 'image/jpeg', 'image/gif'],
  cropOptions = {}
}) {
  const uppload = new Uppload({
    lang: ptBR,
    defaultService: 'local',
    uploader: tusUploader,
    ...upploadOptions
  });

  uppload.use(new Local({ mimeTypes }));
  if (effects.length === 0) {
    uppload.use(new Preview());
  }

  if (effects.includes('crop')) uppload.use(new Crop(cropOptions));
  if (services.includes('camera')) uppload.use(new Camera());
  if (services.includes('unsplash')) uppload.use(new Unsplash(unsplashApiKey));
  if (effects.includes('blur')) uppload.use(new Blur());
  if (effects.includes('brightness')) uppload.use(new Brightness());
  if (effects.includes('constrast')) uppload.use(new Contrast());
  if (effects.includes('grayscale')) uppload.use(new Grayscale());
  if (effects.includes('hueRotate')) uppload.use(new HueRotate());
  if (effects.includes('invert')) uppload.use(new Invert());
  if (effects.includes('saturate')) uppload.use(new Saturate());
  if (effects.includes('sepia')) uppload.use(new Sepia());

  uppload.on('upload', image => {
    image.path = `/uploads${image.url.split('/uploads')[1]}`;
    updateFieldCallback({ target: { value: image } });
  });

  uppload.on('close', () => {
    const elements = document.getElementsByClassName('uppload-container');
    for (const el of elements) {
      el.remove();
    }
  });

  return uppload;
}

export default function UploadImageInput(props) {
  const {
    services,
    effects,
    mimeTypes,
    cropOptions,
    record,
    validate,
    source,
    className,
    helperText,
    upploadOptions,
    ...rest
  } = props;
  const classes = useStyles(props);

  const {
    input: { onChange },
    meta,
    meta: { error, touched },
    isRequired
  } = useInput(props);

  const openModal = useCallback(
    e => {
      e.preventDefault();
      const upploader = createUppload({
        updateFieldCallback: onChange,
        upploadOptions,
        services,
        effects,
        mimeTypes,
        cropOptions
      });
      upploader.open();
    },
    [cropOptions, onChange, effects, mimeTypes, upploadOptions, services]
  );

  return (
    <FormControl
      fullWidth
      variant="filled"
      margin="dense"
      className={classnames(
        classes.root,
        className,
        'MuiFilledInput-root',
        'MuiFilledInput-underline'
      )}
    >
      <Labeled
        {...rest}
        record={record}
        source={source}
        isRequired={isRequired}
        meta={meta}
      />

      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <FormDataConsumer>
            {({ formData, record, ...rest }) => {
              const value = get(formData, source);
              if (!value) {
                return null;
              }

              const styles = { width: '100%', maxWidth: '350px' };
              if (value.type && value.type.match(/video\//i)) {
                return <video src={value.url} style={styles} controls />;
              } else {
                return <ImageField source="url" record={value} {...rest} />;
              }
            }}
          </FormDataConsumer>
        </Grid>
        <Grid item>
          <Tooltip title="Enviar arquivo" aria-label="upload picture">
            <Fab aria-label="upload picture" onClick={openModal}>
              <CloudUploadIcon />
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>

      {helperText && (
        <FormHelperText error={touched && error}>
          <InputHelperText
            helperText={helperText}
            touched={touched}
            error={error}
          />
        </FormHelperText>
      )}
    </FormControl>
  );
}
