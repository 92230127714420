import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const activeRowStyle = (record, index, defaultStyle = {}) => ({
  ...defaultStyle,
  borderLeftColor: record?.active ? green[500] : red[500],
  borderLeftStyle: 'solid',
  borderLeftWidth: 5
});

export default activeRowStyle;
