import {
  BooleanInput,
  FormTab,
  TabbedForm,
  TextInput,
  email,
  required
} from 'react-admin';
// import MaskedInput from 'react-text-mask';
import { UploadImageInput, UploadInput } from '../../custom';

export default function FormCompanies(props) {
  return (
    <TabbedForm {...props} redirect="list">
      <FormTab label="Dados básicos">
        <TextInput
          fullWidth
          label="Nome Fantasia"
          source="name"
          validate={[required()]}
        />

        <TextInput
          autoComplete="off"
          fullWidth
          label="CNPJ"
          source="cnpj"
          validate={[required()]}
          // InputProps={{ inputComponent: MaskedInput, mask: [/d/] }}
        />

        <TextInput
          autoComplete="off"
          fullWidth
          label="Endereço"
          source="address"
          validate={[required()]}
        />

        <UploadImageInput
          fullWidth
          label="Logo"
          source="logo"
          mimeTypes={['image/png']}
          maxSize={[300, 300]}
          helperText="É possível enviar imagens no formato PNG com dimensões recomendadas de 300x300px."
        />

        <UploadImageInput
          fullWidth
          label="Logo para o certificado de horas voluntárias"
          source="logoCertificate"
          mimeTypes={['image/png']}
          maxSize={[300, 300]}
          helperText="É possível enviar imagens no formato PNG com dimensões recomendadas de 300x300px."
        />

        <UploadImageInput
          fullWidth
          label="Imagem de fundo para o certificado de horas voluntárias"
          source="imageCertificate"
          mimeTypes={['image/png']}
          helperText="É possível enviar imagens no formato PNG com dimensões recomendadas de 3508x2480px."
        />

        <UploadInput
          fullWidth
          label="Termos de voluntariado"
          source="termsOfService"
          helperText="Enviar arquivo em formato PDF."
          mimeTypes="application/pdf"
        />

        <BooleanInput label="Ativo" source="active" />
      </FormTab>

      {!props.record.id && (
        <FormTab label="Administrador">
          <TextInput
            autoComplete="off"
            fullWidth
            label="Nome"
            source="admin.name"
            validate={[required()]}
          />
          <TextInput
            autoComplete="off"
            fullWidth
            label="E-mail"
            source="admin.email"
            type="email"
            validate={[required(), email()]}
          />
        </FormTab>
      )}
    </TabbedForm>
  );
}
