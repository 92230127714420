import { CustomCreate } from '../../custom';
import Form from './form';

export default function CreateActivityCategory(props) {
  return (
    <CustomCreate {...props} title="Nova categoria">
      <Form />
    </CustomCreate>
  );
}
