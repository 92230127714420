import icon from '@material-ui/icons/Group';
import create from './create';
import list from './list';
import edit from './edit';

export { create, edit, list };

const teams = {
  name: 'teams',
  options: { label: 'Equipes' },
  icon,
  create,
  edit,
  list
};

export default teams;
