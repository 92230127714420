import React, { useState } from 'react';
import {
  FormHelperText,
  Grid,
  FormControl,
  LinearProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {
  Labeled,
  FormDataConsumer,
  InputHelperText,
  FileInput,
  UrlField
} from 'react-admin';
import { useInput } from 'ra-core';
import get from 'lodash/get';
import { Upload } from 'tus-js-client';
import config from '../../../config';

const useStyles = makeStyles(theme => ({
  root: { boxSizing: 'border-box', padding: '0 12px 12px' },
  upload: {
    '& .previews': { display: 'none' }
  },
  progress: { width: '100%' },
  helperText: { whiteSpace: 'pre-line' }
}));

export default function UploadInput(props) {
  const {
    mimeTypes,
    record,
    validate,
    source,
    className,
    helperText,
    ...rest
  } = props;
  const classes = useStyles(props);

  const {
    input: { onChange },
    meta,
    meta: { error, touched },
    isRequired
  } = useInput(props);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  return (
    <FormControl
      fullWidth
      variant="filled"
      margin="dense"
      className={classnames(
        classes.root,
        className,
        'MuiFilledInput-root',
        'MuiFilledInput-underline'
      )}
    >
      <Labeled
        {...rest}
        record={record}
        source={source}
        isRequired={isRequired}
        meta={meta}
      />

      <Grid container margin={2} alignItems="center">
        <FormDataConsumer>
          {({ formData, record, ...rest }) => {
            if (formData && formData.upload) delete formData.upload;

            const value = get(formData, source);

            if (isUploading || !value) return null;

            if (value.type && value.type.match(/image\//i))
              return (
                <img alt="" src={value.url} style={{ maxWidth: '180px' }} />
              );
            else return <UrlField source="url" record={value} />;
          }}
        </FormDataConsumer>

        <FileInput
          source="upload"
          multiple={false}
          disabled={isUploading}
          className={classnames(classes.upload)}
          accept={mimeTypes}
          onChange={file => {
            setIsUploading(true);
            const upload = new Upload(file, {
              endpoint: config.UPLOAD,
              removeFingerprintOnSuccess: true,
              headers: {
                Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`
              },
              retryDelays: [0, 3000, 5000, 10000, 20000],
              metadata: {
                name: file.name,
                type: file.type
              },
              onError: function (error) {
                setUploadProgress(0);
                setIsUploading(false);
              },
              onProgress: function (bytesUploaded, bytesTotal) {
                const progress = (bytesUploaded / bytesTotal) * 100;
                setUploadProgress(progress);
              },
              onSuccess: function () {
                const { name, size, type } = file;
                const { url } = upload;

                const path = `/uploads${url.split('/uploads')[1]}`;
                const value = { name, size, type, url, path };

                onChange({ target: { value } });
                setUploadProgress(0);
                setIsUploading(false);
              }
            });
            upload.start();
          }}
        >
          <p></p>
        </FileInput>

        <div className={classes.progress}>
          {isUploading && (
            <LinearProgress variant="determinate" value={uploadProgress} />
          )}
        </div>
      </Grid>

      {helperText && (
        <FormHelperText className={classes.helperText} error={touched && error}>
          <InputHelperText
            helperText={helperText}
            touched={touched}
            error={error}
          />
        </FormHelperText>
      )}
    </FormControl>
  );
}
