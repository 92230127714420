import React, { cloneElement, memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import { useDrag, useDrop } from 'react-dnd';
import arrayMove from 'array-move';

import DatagridRow from './DatagridRow';
import { app } from '../../App';

const Row = ({
  id,
  rowIndex,
  moveRecords,
  filterColumn,
  toggleLoading,
  ...props
}) => {
  const type = filterColumn ? `ROW-${props.record[filterColumn]}` : 'ROW';
  const [{ isDragging }, dragRef] = useDrag({
    item: { type, id, rowIndex, filterColumn: props.record[filterColumn] },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    end(item, monitor) {
      if (monitor.didDrop()) {
        let params = {
          resource: props.resource,
          fromId: item.id,
          toId: item.hoverId
        };
        if (filterColumn)
          params = {
            ...params,
            conditions: { [filterColumn]: item.filterColumn }
          };

        toggleLoading(true);
        app
          .service('sort')
          .create(params)
          .then(() => {
            toggleLoading(false);
          })
          .catch(() => {
            toggleLoading(false);
          });
      }
    }
  });

  const [, dropRef] = useDrop({
    accept: type,
    hover(item, monitor) {
      const { rowIndex: dragIndex } = item;
      const hoverIndex = rowIndex;
      const hoverId = id;

      if (dragIndex === hoverIndex) return;

      moveRecords(dragIndex, hoverIndex);
      item.rowIndex = hoverIndex;
      item.hoverId = hoverId;
    }
  });

  return (
    <DatagridRow
      ref={node => dragRef(dropRef(node))}
      {...{ ...props, id }}
      isDragging={isDragging}
    />
  );
};

function DatagridBody({
  basePath,
  children,
  classes,
  className,
  data,
  expand,
  hasBulkActions,
  hover,
  ids,
  onToggleItem,
  resource,
  row,
  rowClick,
  rowStyle,
  selectedIds,
  styles,
  version,
  isRowSelectable,
  filterColumn,
  ...rest
}) {
  const defaultRowProps = {
    basePath,
    classes,
    expand,
    hasBulkActions,
    hover,
    onToggleItem,
    resource,
    rowClick
  };

  const [records, setRecords] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRecords(ids);
  }, [ids]);

  const moveRecords = async (
    draggedRowIndex,
    droppedRowIndex,
    draggedId,
    droppedId
  ) => {
    setRecords(arrayMove(records, draggedRowIndex, droppedRowIndex));
  };

  function toggleLoading(loading) {
    setLoading(loading);
  }

  return (
    <TableBody
      className={classnames('datagrid-body', className)}
      style={{ pointerEvents: loading ? 'none' : 'unset' }}
      {...rest}
    >
      {records.map((id, rowIndex) =>
        cloneElement(
          row,
          {
            ...defaultRowProps,
            filterColumn,
            rowIndex,
            className: classnames(classes.row, {
              [classes.rowEven]: rowIndex % 2 === 0,
              [classes.rowOdd]: rowIndex % 2 !== 0,
              [classes.clickableRow]: rowClick
            }),
            record: data[id],
            moveRecords,
            toggleLoading,
            id,
            key: id,
            selectable: !isRowSelectable || isRowSelectable(data[id]),
            selected: selectedIds.includes(id),
            style: rowStyle ? rowStyle(data[id], rowIndex) : null
          },
          children
        )
      )}
    </TableBody>
  );
}

DatagridBody.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  data: PropTypes.object.isRequired,
  expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  hasBulkActions: PropTypes.bool.isRequired,
  hover: PropTypes.bool,
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  onToggleItem: PropTypes.func,
  resource: PropTypes.string,
  row: PropTypes.element,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowStyle: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  styles: PropTypes.object,
  isRowSelectable: PropTypes.func,
  version: PropTypes.number
};

DatagridBody.defaultProps = {
  data: {},
  hasBulkActions: false,
  ids: [],
  row: <Row />
};
// trick material-ui Table into thinking this is one of the child type it supports
DatagridBody.muiName = 'TableBody';

const areEqual = (prevProps, nextProps) => {
  const { children: _, ...prevPropsWithoutChildren } = prevProps;
  const { children: __, ...nextPropsWithoutChildren } = nextProps;
  return isEqual(prevPropsWithoutChildren, nextPropsWithoutChildren);
};

export const PureDatagridBody = memo(DatagridBody, areEqual);
// trick material-ui Table into thinking this is one of the child type it supports
PureDatagridBody.muiName = 'TableBody';
PureDatagridBody.defaultProps = {
  row: <Row />
};

export default DatagridBody;
