import { createMuiTheme } from '@material-ui/core';
import { defaultTheme } from 'react-admin';
import merge from 'lodash.merge';

const theme = createMuiTheme(
	merge(
		{},
		defaultTheme,
		{
			palette: {
				primary: {
					main: '#6a94fc',
					contrastText: '#fff',
				}
			},
			typography: {
				fontSize: 12.5,
				fontFamily: [
					'Poppins',
					'-apple-system',
					'BlinkMacSystemFont',
					'"Segoe UI"',
					'Roboto',
					'"Helvetica Neue"',
					'Arial',
					'sans-serif',
					'"Apple Color Emoji"',
					'"Segoe UI Emoji"',
					'"Segoe UI Symbol"',
				].join(',')
			},
			overrides: {
				MuiCssBaseline: {
					'@global': {
						body: {
							margin: 0
						},
					},
				},
			},
		}
	)
);

export default theme;
