import React from 'react';
import { Avatar } from '@material-ui/core';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import get from 'lodash/get';

export default function MediaField({
  label,
  source,
  record = {},
  basePath,
  ...props
}) {
  const value = get(record, source);

  if (!value) {
    return null;
  }

  if (value.type?.match(/video\//i)) {
    return (
      <Avatar {...props}>
        <VideoLibraryIcon />;
      </Avatar>
    );
  } else {
    return <Avatar {...props} alt="" src={value.url} />;
  }
}
