import { Menu, styled } from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { useState } from 'react';
import {
  Button,
  Datagrid,
  EmailField,
  ReferenceField,
  TextField,
  useListContext,
  useNotify,
  useRefresh,
  useUpdate
} from 'react-admin';
import { CustomList } from '../../custom';

const StyledMenu = styled(Menu)({
  '& .MuiList-root': {
    padding: 8
  }
});


function ApprovalButtonField({ record }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actionOptions = {
    onFailure: error => {
      notify(
        `Ocorreu uma falha e não foi possível realizar a aprovação: ${error}`,
        'warning'
      )
    }
  }

  const [approveAction] = useUpdate(
    'registrations',
    record.id,
    { action: 'approve' },
    {},
    { 
      onSuccess: () => {
        refresh();
        notify('Aprovação realizada com sucesso!');
      },
      ...actionOptions
    }
  );

  const [rejectAction] = useUpdate(
    'registrations',
    record.id,
    { action: 'reject' },
    {},
    { 
      ...actionOptions,
      onSuccess: () => {
        refresh();
        notify('Cadastro rejeitado com sucesso!');
      },
    }
  );

  const { filter, filterValues } = useListContext();
  return (
    <>
      <Button
        id="export-button"
        aria-controls="export-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        label="Aprovação"
      >
        <AssignmentTurnedInIcon />
      </Button>
      <StyledMenu
        id="export-menu"
        anchorEl={anchorEl}
        elevation={1}
        open={open}
        onClose={handleClose}
        sx={{ '.MuiList-root': { backgroundColor: 'red' } }}
        MenuListProps={{
          'aria-labelledby': 'export-button'
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
      >
        <Button
          disabled={false}
          label="Aceitar"
          onClick={approveAction}
        >
          <CheckIcon />
        </Button>
        <Button
          disabled={false}
          label="Rejeitar"
          onClick={rejectAction}
          style={{ marginLeft: 10 }}
        >
          <ClearIcon /> 
        </Button>
      </StyledMenu>
    </>
  );
}

export default function ListRegistrations(props) {
  return (
    <CustomList
      {...props}
      bulkActionButtons={false}
      title={props.options.label}
      sort={{ field: 'createdAt', order: 'ASC' }}
    >
      <Datagrid>
        <TextField label="Nome" source="name" />

        <EmailField label="E-mail" source="email" />

        <TextField label="Telefone" source="phone" />

        <ReferenceField source="company" reference="companies" label="Empresa">
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField source="team" reference="teams" label="Equipe">
          <TextField source="name" />
        </ReferenceField>

        <ApprovalButtonField
          textAlign="center"
          label="Aprovação"
          sortable={false}
        />

      </Datagrid>
    </CustomList>
  );
}
