import {
  BooleanInput,
  DateInput
  // ReferenceInput,
  // SelectInput
  ,
  SimpleForm,
  TextInput,
  required
} from 'react-admin';
import { CustomEditor, UploadImageInput } from '../../custom';

function dateFormatter(v) {
  if (v) {
    const [d, m, y] = new Date(v)
      .toLocaleDateString('pt-BR', { timeZone: 'UTC' })
      .split('/');
    return `${y.padStart(4, '0')}-${m.padStart(2, '0')}-${d.padStart(2, '0')}`;
  } else {
    return v;
  }
}

export default function FormCompanyPosts(props) {
  return (
    <SimpleForm {...props} redirect="list">
      <TextInput
        fullWidth
        label="Título"
        source="name"
        validate={[required()]}
      />

      <DateInput
        format={dateFormatter}
        label="Data"
        source="date"
        validate={[required()]}
      />

      <CustomEditor
        fullWidth
        label="Conteúdo"
        source="content"
        validate={[required()]}
      />

      <UploadImageInput
        fullWidth
        label="Imagem"
        source="image"
        upploadOptions={{
          maxHeight: 400,
          maxWidth: 680
        }}
        mimeTypes={['image/jpeg']}
        helperText="É possível enviar imagens no formato JPG com dimensões recomendadas de 680x400px."
        cropOptions={{
          aspectRatio: 680 / 400
        }}
      />

      {!props.record._id && (
        <BooleanInput label="Notificar" source="sendNotification" />
      )}

      {props.record._id && <BooleanInput label="Ativo" source="active" />}
    </SimpleForm>
  );
}
