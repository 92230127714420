import { CustomEdit } from '../../custom';
import Form from './form';

export default function EditSalaryOption(props) {
  return (
    <CustomEdit {...props} title="Editar opção">
      <Form />
    </CustomEdit>
  );
}
