import icon from '@material-ui/icons/Business';
import create from './create';
import edit from './edit';
import list from './list';

export { create, edit, list };

const activityCategories = {
  name: 'activity-categories',
  options: { label: 'Categorias das atividades' },
  icon,
  create,
  edit,
  list
};

export default activityCategories;
