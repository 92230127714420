import icon from '@material-ui/icons/Person';
import create from './create';
import list from './list';
import edit from './edit';

export { create, edit, list };

const companyUsers = {
  name: 'companyUsers',
  options: { label: 'Voluntários' },
  icon,
  create,
  edit,
  list
};

export default companyUsers;
