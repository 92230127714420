import decodeJwt from 'jwt-decode';

function authClient(client, options = {}) {
  const {
    storageKey,
    authenticate,
    permissionsKey,
    permissionsField,
    passwordField,
    usernameField,
    redirectTo,
    logoutOnForbidden,
    userKey
  } = {
    storageKey: 'token',
    authenticate: { strategy: 'local' },
    permissionsKey: 'permissions',
    permissionsField: 'roles',
    passwordField: 'password',
    usernameField: 'email',
    logoutOnForbidden: true,
    userKey: 'user',
    ...options
  };

  return {
    login(data) {
      const { username, password } = data;
      return client
        .authenticate({
          ...authenticate,
          [usernameField]: username,
          [passwordField]: password
        })
        .then(({ user }) => {
          const permissions = [];
          const rolesValue = 1; //ADMIN

          if (user.userType === 'SUPERADMIN') {
            permissions.push('SUPERADMIN');
          }

          if (
            user.userType === 'SUPERADMIN' ||
            (user.userType === 'COMPANY' && rolesValue & user.companyUserType)
          ) {
            permissions.push('ADMIN');
          }

          if (!permissions.length) {
            localStorage.removeItem(storageKey);
            throw new Error('Você não é um administrador.');
          }

          localStorage.setItem(permissionsKey, JSON.stringify(permissions));
          localStorage.setItem(
            userKey,
            JSON.stringify({ ...user, id: user._id, fullName: user.name })
          );
        });
    },
    logout() {
      return client.logout().then(() => {
        localStorage.removeItem(permissionsKey);
        localStorage.removeItem(userKey);
      });
    },
    checkAuth() {
      const hasJwtInStorage = !!localStorage.getItem(storageKey);
      const hasReAuthenticate =
        Object.getOwnPropertyNames(client).includes('reAuthenticate') &&
        typeof client.reAuthenticate === 'function';

      if (hasJwtInStorage && hasReAuthenticate) {
        return client
          .reAuthenticate()
          .then(() => Promise.resolve())
          .catch(() => Promise.reject({ redirectTo }));
      }

      return hasJwtInStorage
        ? Promise.resolve()
        : Promise.reject({ redirectTo });
    },
    checkError(error) {
      const { code } = error;
      if (code === 401 || (logoutOnForbidden && code === 403)) {
        localStorage.removeItem(storageKey);
        localStorage.removeItem(permissionsKey);
        return Promise.reject();
      }
      return Promise.resolve();
    },
    async getPermissions() {
      /*
      JWT token may be provided by oauth,
      so that's why the permissions are decoded here and not in AUTH_LOGIN.
      */
      // Get the permissions from localstorage if any.
      const localStoragePermissions = JSON.parse(
        localStorage.getItem(permissionsKey)
      );
      // If any, provide them.
      if (localStoragePermissions) {
        return localStoragePermissions;
      }
      // Or find them from the token, save them and provide them.
      try {
        const jwtToken = localStorage.getItem(storageKey);
        const decodedToken = decodeJwt(jwtToken);
        const jwtPermissions = decodedToken[permissionsField]
          ? decodedToken[permissionsField]
          : [];
        localStorage.setItem(permissionsKey, JSON.stringify(jwtPermissions));
        return jwtPermissions;
      } catch (e) {
        return Promise.reject();
      }
    },
    async getIdentity() {
      // Get the user from localstorage if set.
      const localStorageUser = JSON.parse(localStorage.getItem(userKey));
      // If any, provide them.
      if (localStorageUser) {
        return localStorageUser;
      }
      // Or find them from the token, save them and provide them.
      try {
        const jwtToken = localStorage.getItem(storageKey);
        const decodedToken = decodeJwt(jwtToken);

        function saveUser(user) {
          if (user) {
            localStorage.setItem(
              userKey,
              JSON.stringify({ ...user, id: user._id, fullName: user.name })
            );
            return user;
          } else {
            return Promise.reject();
          }
        }

        const permissions = await this.getPermissions();
        const user = await client
          .service(
            permissions.includes('SUPERADMIN') ? 'adminUsers' : 'companyUsers'
          )
          .get(decodedToken['sub']);
        return saveUser(user);
      } catch (e) {
        return Promise.reject();
      }
    }
  };
}

export default function authProvider(app) {
  return authClient(app, {
    storageKey: 'feathers-jwt' // The key in localStorage used to store the authentication token
  });
}
