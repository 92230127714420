import {
  AutocompleteArrayInput,
  ChipField,
  ReferenceArrayInput,
  ReferenceManyField,
  SimpleForm,
  SingleFieldList,
  TextInput,
  required
} from 'react-admin';

export default function Formteams(props) {

  return (
    <SimpleForm {...props} redirect="list">
      <TextInput fullWidth label="Nome" source="name" validate={[required()]} />

      {props.record.id && (
        <ReferenceArrayInput
          label="Líderes"
          source="leader"
          reference="companyUsers"
          perPage={-1}
          filter={{ team: props.record.id, active: true }}
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty
        >
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
      )}

      <ReferenceManyField
        label="Voluntários da equipe"
        reference="companyUsers"
        target="team"
        sort={{ field: 'name', order: 'ASC'}}
        perPage={-1}
      >
          <SingleFieldList linkType="show">
              <ChipField source="name" />
          </SingleFieldList>
      </ReferenceManyField>

    </SimpleForm>
  );
}
