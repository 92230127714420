import React from 'react';
import { Layout as BaseLayout } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import theme from './themes';

export default function Layout(props) {
  return (
    <BaseLayout
      {...props}
      appBar={AppBar}
      menu={Menu}
      theme={theme}
    />
  );
}
