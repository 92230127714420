import React, { useCallback, useEffect } from 'react';
import {
  useAuthProvider,
  useGetIdentity,
  useGetList,
  useMutation
} from 'react-admin';
import Avatar from '@material-ui/core/Avatar';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';

const StyledSelect = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex'
  }
})(Select);

const StyledAvatar = withStyles({
  root: {
    height: 30,
    marginRight: 10,
    width: 30
  }
})(Avatar);

const useStyles = makeStyles(
  theme => ({
    input: {
      minWidth: theme.spacing(20),
      marginLeft: 16,
      marginBottom: 6
    }
  }),
  { name: 'RaSelectInput' }
);

export default function CompanySelector(props) {
  const classes = useStyles(props);
  const { identity: user } = useGetIdentity();
  const authProvider = useAuthProvider();

  const companies = useGetList(
    'companies',
    null,
    { field: 'name', order: 'ASC' },
    { active: true }
  );

  useEffect(() => {
    if (!companies.loading) {
      companies.refetch();
    }
    if (
      companies.loaded &&
      !companies.loading &&
      companies.total === 0 &&
      user.company
    ) {
      handleChange({ target: { value: null } });
    }
  }, [companies.total, user]); //eslint-disable-line react-hooks/exhaustive-deps

  const [mutate, { data, error, loaded }] = useMutation();

  const handleChange = useCallback(
    event =>
      mutate({
        type: 'update',
        resource: 'adminUsers',
        payload: {
          id: user._id,
          data: { company: event.target.value || null }
        }
      }),
    [user, mutate]
  );

  useEffect(() => {
    if (loaded && !error && Object.keys(data).length) {
      async function getIdentity() {
        localStorage.setItem(
          'user',
          JSON.stringify({ ...data, id: data._id, fullName: data.name })
        );
        await authProvider.getIdentity();
        window.location.reload();
      }
      getIdentity();
    }
  }, [authProvider, data, error, loaded]);

  if (!user || !companies.loaded) {
    return null;
  }

  return (
    <StyledSelect
      className={classes.input}
      defaultValue={user.company || ''}
      displayEmpty
      onChange={handleChange}
    >
      <MenuItem key="none" value="">
        Nenhuma
      </MenuItem>
      {companies.ids.map(id => (
        <MenuItem key={id} value={id}>
          <StyledAvatar alt="" src={companies.data[id].logo?.url} />
          {companies.data[id].name}
        </MenuItem>
      ))}
    </StyledSelect>
  );
}
