import React from 'react';
import { useResourceContext } from 'react-admin';
import { makeStyles, Typography } from '@material-ui/core';
import resources from '../../resources'

export const useStyles = makeStyles((theme) => ({
  root: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: 22,
    fontWeight: 700,
    margin: '20px 0 0',
    '&::after': {
      height: 5,
      content: '""',
      display: 'block',
      flexGrow: 1,
      flexShrink: 1,
      marginLeft: 20,
      backgroundColor: theme.palette.primary.main
    }
  }
}));

function AdminTitle() {
  const resourceName = useResourceContext();
  const classes = useStyles();

  if (!resourceName)
    return null;

  const currentResource = Object.values(resources).find(resource => resource.name === resourceName);
  if (!currentResource)
    return null;

  return (
    <Typography
      variant="h2"
      color="inherit"
      className={classes.root}
    >
      {currentResource?.options?.label}
    </Typography>
  )
}

export default AdminTitle;