import { CustomCreate } from '../../custom';
import Form from './form';

export default function CreateAdminUsers(props) {
  return (
    <CustomCreate {...props} title="Novo administrador">
      <Form />
    </CustomCreate>
  );
}
