import {
  SimpleForm,
  TextInput,
  email,
  BooleanInput,
  required,
  useGetIdentity,
  Toolbar,
  SaveButton
} from 'react-admin';

export default function FormAdminUsers(props) {
  const { identity } = useGetIdentity();

  function FormAdminUsersToolbar(props) {
    return (
      <Toolbar {...props}>
        <SaveButton disabled={props.pristine || props.validating} />
      </Toolbar>
    );
  }

  return (
    <SimpleForm 
      {...props} 
      toolbar={<FormAdminUsersToolbar />}
      redirect="list">
      <TextInput fullWidth label="Nome" source="name" validate={[required()]} />
      <TextInput
        autoComplete="off"
        fullWidth
        label="E-mail"
        source="email"
        type="email"
        validate={[required(), email()]}
      />

      <TextInput
        autoComplete="off"
        fullWidth
        label="Senha"
        source="password"
        validate={props.record._id ? null : required()}
        type="password"
      />

      {props.record.id && !!identity && identity.id !== props.record.id && (
        <BooleanInput label="Ativo" source="active" />
      )}
    </SimpleForm>
  );
}
