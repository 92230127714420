import {
  BooleanInput,
  Datagrid,
  Filter,
  TextField,
  TextInput
} from 'react-admin';
import {
  CustomList,
  MediaField,
  StatusField,
  activeRowStyle
} from '../../custom';

function Filters(props) {
  return (
    <Filter {...props}>
      <TextInput fullWidth label="Buscar por nome" source="name[$regex]" />
      <BooleanInput label="Ativo" source="active" />
    </Filter>
  );
}

export default function ListActivityCategories(props) {
  return (
    <CustomList
      {...props}
      filters={<Filters />}
      title={props.options.label}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid rowStyle={activeRowStyle} rowClick="edit">
        <MediaField label="Ícone" source="icon" />
        <TextField label="Nome" source="name" />
        <TextField label="Tipo" source="type" />
        <StatusField label="Ativo" />
      </Datagrid>
    </CustomList>
  );
}
