import jsonExport from 'jsonexport';
import { downloadCSV } from 'react-admin';

export function usersExporter({ companyId, filter, filterValues }) {
  return async (_, __, dataProvider) => {
    let users = await dataProvider.getList('reports-exports', {
      filter: {
        ...filter,
        ...filterValues,
        type: 'volunteers',
        company: companyId
      },
      pagination: false,
      sort: { field: 'name', order: 'ASC' }
    });

    users = users.map(({ _id, id, ...user }) => ({
      ...user
    }));

    jsonExport(
      users,
      {
        headers: ['name', 'createdAt', 'gender', 'registration', 'email', 'phone', 'state', 'city', 'birthdate', 'education', 'averageSalary', 'averagePeopleAtHome', 'volunteerTime', 'tShirtSize', 'volunteerWorkFrequency', 'teamName', 'companyName', 'active', 'hours', 'hourlyRate', 'amount', 'acceptedTermsApp', 'acceptedTermsCompany'],
        rename: [
          'Nome',
          'Criado em',
          'Sexo',
          'Matrículo na empresa',
          'Email',
          'Telefone',
          'Estado',
          'Cidade',
          'Nascimento',
          'Escolaridade',
          'Média salarial',
          'Média de pessoas na casa',
          'Tempo de voluntariado',
          'Tamanho de camiseta',
          'Frequência trabalho voluntário',
          'Nome da equipe',
          'Nome da empresa',
          'Ativo',
          'Horas de Participação',
          'Valor da hora',
          'Valor total',
          'Aceitou termos do App?',
          'Aceitou termos da empresa?'
        ],
        rowDelimiter: ';'
      },
      (err, csv) => {
        if (err) {
          console.error(err);
          throw err;
        }
        downloadCSV(
          '\ufeff' + csv,
          `Voluntários da ${companyId ? 'Empresa' : 'Plataforma'}`
        );
      }
    );
  };
}

export function activitiesExporter({ companyId, filter, filterValues }) {
  return async (_, __, dataProvider) => {
    let activities = await dataProvider.getList('reports-exports', {
      filter: {
        ...filter,
        ...filterValues,
        type: 'activities',
        company: companyId
      },
      pagination: false,
      sort: { field: 'name', order: 'ASC' }
    });

    activities = activities.map(({ _id, id, ...activity }) => ({
      ...activity
    }));

    jsonExport(
      activities,
      {
        headers: [
          'createdAt',
          'name',
          'category',
          'startDate',
          'endDate',
          'place',
          'cityName',
          'stateName',
          'teamName',
          'allTeams',
          'hidden',
          'description',
          'finished',
          'countSubscribers',
          'subscribersNames',
          'countCheckins',
          'checkinsNames',
          'countParticipants',
          'participantsNames',
          'investedValue',
          'collectedValue',
          'benefitedQuantity',
          'timeSpent',
          'countLikes',
          'countComments',
          'reaction1',
          'reaction2',
          'reaction3',
          'reaction4',
          'reaction5',
          'collectedItems',
          'summary',
          'audience',
          'ods',
          'skills',
          'createdByName'
        ],
        rename: [
          'Data de Inclusão',
          'Nome',
          'Categoria',
          'Data inicial',
          'Data final',
          'Local',
          'Cidade',
          'Estado',
          'Nome da equipe',
          'Aberta para todas as equipes',
          'Oculto',
          'Descrição',
          'Finalizada',
          'Número de interessados',
          'Nomes dos interessados',
          'Número de checkins',
          'Nomes de quem fez checkin',
          'Número de participantes',
          'Nomes dos participantes',
          'Valor investido',
          'Valor arrecadado',
          'Número de beneficiados',
          'Número de horas voluntárias',
          'Número de likes',
          'Número de comentários',
          'Reação 1',
          'Reação 2',
          'Reação 3',
          'Reação 4',
          'Reação 5',
          'Itens doados',
          'Como foi a ação?',
          'Público beneficiado',
          'ODS',
          'Competências',
          'Criada por'
        ],
        rowDelimiter: ';'
      },
      (err, csv) => {
        if (err) {
          console.error(err);
          throw err;
        }
        downloadCSV('\ufeff' + csv, 'Ações da Empresa');
      }
    );
  };
}
