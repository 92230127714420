import React from 'react';
import { ListButton, TopToolbar } from 'react-admin';

function ActionListButton({ basePath }) {
  return (
    <TopToolbar>
      <ListButton basePath={basePath} />
    </TopToolbar>
  );
}

export default function formWrapper(props) {
  return WrappedComponent => (
    <WrappedComponent
      redirect="list"
      actions={<ActionListButton />}
      {...props}
    />
  );
}
