import { CustomEdit } from '../../custom';
import Form from './form';

export default function EditActivityCategory(props) {
  return (
    <CustomEdit {...props} title="Editar categoria">
      <Form />
    </CustomEdit>
  );
}
