import icon from '@material-ui/icons/SupervisorAccount';
import create from './create';
import list from './list';
import edit from './edit';

export { create, edit, list };

const adminUsers = {
  name: 'adminUsers',
  options: { label: 'Administradores' },
  icon,
  create,
  edit,
  list
};

export default adminUsers;
