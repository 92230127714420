import { Edit, SimpleForm, Toolbar, SaveButton } from 'react-admin';
import { UploadInput } from '../../custom';
import AdminTitle from '../../custom/AdminTitle';

function EditSettingsToolbar(props) {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine || props.validating} />
    </Toolbar>
  );
}

export default function EditSettings(props) {
  return (
    <>
      <AdminTitle />
      <Edit {...props} id="me" title="Configurações">
        <SimpleForm toolbar={<EditSettingsToolbar />}>
          <UploadInput
            fullWidth
            label="Termos de uso do aplicativo"
            source="termsOfService"
            helperText="Enviar arquivo em formato PDF."
            mimeTypes="application/pdf"
          />
        </SimpleForm>
      </Edit>
    </>
  );
}
