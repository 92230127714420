import icon from '@material-ui/icons/Mail';
import create from './create';
import show from './show';
import list from './list';

export { create, show, list };

const notifications = {
  name: 'notifications',
  options: { label: 'Notificações' },
  icon,
  create,
  list
};

export default notifications;
