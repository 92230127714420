import React /* , { forwardRef } */ from 'react';
import {
  AppBar as BaseAppBar /*, MenuItemLink, useTranslate */
} from 'react-admin';
// import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';

import Logo from '../assets/logo-h.png';

const useStyles = makeStyles({
  logo: {
    width: 170
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  }
});

// const ConfigurationMenu = forwardRef((props, ref) => {
//     const translate = useTranslate();
//     return (
//         <MenuItemLink
//             ref={ref}
//             to="/configuration"
//             primaryText={translate('pos.configuration')}
//             leftIcon={<SettingsIcon />}
//             onClick={props.onClick}
//         />
//     );
// });

export default function AppBar(props) {
  const classes = useStyles();
  return (
    <BaseAppBar {...props}>
      <img src={Logo} alt="App do Ben" className={classes.logo} />
      <span className={classes.spacer} />
    </BaseAppBar>
  );
}
