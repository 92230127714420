import icon from '@material-ui/icons/Business';
import create from './create';
import list from './list';
import edit from './edit';

export { create, edit, list };

const companies = {
  name: 'companies',
  options: { label: 'Empresas' },
  icon,
  create,
  edit,
  list
};

export default companies;
