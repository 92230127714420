export const ptBR = {
  upload: 'Upload',
  cancel: 'Cancelar',
  close: 'Fechar',
  uploading: 'Enviando...',
  uploaded: 'Enviado',
  fetching: 'Obtendo sua imagem de $1$...',
  poweredBy: 'Desenvolvido por $1$',
  needHelp: 'Precisa de ajuda?',

  units: {
    px: 'px',
    '%': '%',
    deg: '°'
  },

  errors: {
    response_not_ok: 'Ocorreu um erro ao buscar este arquivo',
    unable_to_search: 'Ocorreu um erro ao pesquisar',
    invalid_service_url: 'Essa não é $A$ $1$ URL',
    invalid_url: 'Essa URL parece ser inválida',
    upload_aborted: 'Seu upload foi cancelado',
    upload_error: 'Ocorreu um erro ao carregar este arquivo',
    file_type_not_allowed: 'Esse tipo de arquivo não é permitido',
    file_too_large: 'Seu arquivo deve ser menor que $1$'
  },

  help: {
    loading: 'Carregando ajuda...',
    close: 'Fechar ajuda'
  },

  services: {
    default: {
      heading: 'Selecione uma imagem'
    },
    local: {
      title: 'Escolher arquivo',
      button: 'Selecione um arquivo',
      or: 'ou',
      drop: 'Solte arquivos aqui'
    },
    camera: {
      title: 'Câmera',
      button: 'Clique na foto',
      switch: 'Mudar câmera',
      waiting: 'Aguardando permissão...',
      unableToRead:
        'Não podemos ler o vídeo da sua câmera. Isso pode ocorrer porque você não concedeu a permissão necessária ou porque seu dispositivo não suporta o acesso à câmera.'
    },
    microlink: {
      button: 'Importar de $1$',
      label: '$1$ $2$ URL',
      placeholder: 'Digite $A$ $1$ $2$ URL',
      type: 'post'
    },
    url: {
      title: 'URL direta',
      label: 'URL da imagem',
      placeholder: 'Digite a URL de uma imagem'
    },
    instagram: {
      title: 'Instagram'
    },
    facebook: {
      title: 'Facebook'
    },
    flickr: {
      title: 'Flickr',
      type: 'photo'
    },
    ninegag: {
      title: '9GAG',
      type: 'photo'
    },
    deviantart: {
      title: 'DeviantArt'
    },
    artstation: {
      title: 'ArtStation'
    },
    twitter: {
      title: 'Twitter',
      type: 'image tweet'
    },
    pinterest: {
      title: 'Pinterest',
      type: 'pin'
    },
    flipboard: {
      title: 'Flipboard',
      type: 'article'
    },
    fotki: {
      title: 'Fotki',
      type: 'photo'
    },
    linkedin: {
      title: 'LinkedIn'
    },
    reddit: {
      title: 'Reddit'
    },
    tumblr: {
      title: 'Tumblr'
    },
    weheartit: {
      title: 'We Heart It'
    },
    screenshot: {
      title: 'Captura de tela',
      button: 'Tirar captura de tela',
      label: 'URL da página da Web',
      placeholder: 'Digite a URL de uma página da Web',
      loading: 'Tirando uma captura de tela...'
    },
    search: {
      button: 'Buscar em $1$',
      label: 'Encontre uma imagem',
      placeholder: 'Procure por algo',
      imagesPoweredBy: 'Imagens fornecidas por $1$'
    },
    giphy: {
      title: 'GIPHY'
    },
    unsplash: {
      title: 'Unsplash'
    },
    pixabay: {
      title: 'Pixabay'
    },
    pexels: {
      title: 'Pexels'
    }
  },

  effects: {
    preview: {
      title: 'Pré-visualização'
    },
    filters: {
      title: 'Filtros'
    },
    crop: {
      title: 'Crop',
      aspectRatios: {
        free: 'Livre',
        square: 'Quadrado'
      }
    },
    rotate: {
      title: 'Rotate'
    },
    flip: {
      title: 'Flip',
      buttons: {
        horizontal: 'Horizontal',
        vertical: 'Vertical'
      }
    },
    sharpen: {
      title: 'Sharpen'
    },
    blur: {
      title: 'Blur'
    },
    brightness: {
      title: 'Brightness'
    },
    contrast: {
      title: 'Contrast'
    },
    grayscale: {
      title: 'Grayscale'
    },
    'hue-rotate': {
      title: 'Hue rotate'
    },
    invert: {
      title: 'Invert'
    },
    saturate: {
      title: 'Saturate'
    },
    sepia: {
      title: 'Sepia'
    }
  },

  /**
   * Helpers can transform a string before returning it
   * You can define a language-specific helper here
   */
  helper: text => {
    // Replace all instances of $A$ with "a" or "an"
    const AAN = '$A$';
    while (text.indexOf(AAN) !== -1) {
      const index = text.indexOf(AAN);
      if (text.length > index + 3) {
        if (
          ['a', 'e', 'i', 'o', 'u'].indexOf(text[index + 4].toLowerCase()) !==
          -1
        ) {
          text = text.replace(AAN, 'an');
        } else {
          text = text.replace(AAN, 'a');
        }
      }
    }
    return text;
  }
};

export default ptBR;
