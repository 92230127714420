import { Upload } from 'tus-js-client';
import config from '../../config';

export default function tusUploader(file, updateProgress) {
  return new Promise((resolve, reject) => {
    const upload = new Upload(file, {
      endpoint: config.UPLOAD,
      removeFingerprintOnSuccess: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`
      },
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        name: file.name,
        type: file.type
      },
      onError: function (error) {
        reject(error);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        updateProgress(percentage);
      },
      onSuccess: function (a) {
        const { name, size, type } = file;
        const { url } = upload;
        resolve({ name, size, type, url });
      }
    });
    upload.start();
  });
}
