
import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { createMuiTheme, makeStyles, withStyles, Typography, CssBaseline } from '@material-ui/core';
import Background from '../assets/bg-login.jpg';
import theme from './themes';
import merge from 'lodash.merge';

const useStyles = makeStyles((theme) => ({
  logo: {
    marginBottom: 35
  }, 
  text: {
    color: '#FFF',
    textAlign: 'center',
  }
}));

const styles = {
  main: { 
    backgroundImage: `url(${Background})`,
    justifyContent: 'center'
  },
  card: {
    background: 'transparent',
    boxShadow: 'none',
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  icon: {
    display: 'none'
  }
};

const CustomLoginForm = withStyles({
  button: { 
    background: '#fabf0c', 
    borderRadius: 20,
    height: 36,
    width: 108,
    marginLeft: 'auto',
    boxShadow: 'none'
  }
})(LoginForm);

const loginTheme = createMuiTheme(
  merge(
    {},
    theme,
    {
      overrides: {
        MuiFormHelperText: {
          root: {
            display: 'none'
          }
        },
        MuiCardActions: {
          root: {
            padding: '1em'
          }
        }, 
        MuiFormLabel: {
          root: {
            '&.Mui-focused': {
              color: '#fff',
            }
          }
        },
        MuiInputLabel: {
          root: {
            zIndex: 1,
            color: '#6a94fc'
          },
          formControl: {
            left: 15
          }
        },
        MuiInput: {
          root: {  
            borderRadius: 20,
            minWidth: 195
          },
          input: {
            borderRadius: 20,
            background: '#fff',
            padding: 12
          },
          underline: {
            '&::before': {
              display: 'none'
            },
            '&::after': {
              display: 'none'
            }
          }
        }
      },
      props: {
        MuiButtonBase: {
          filled: 'true'
        },
      },
    }
  )
)

const CustomLoginPage = props => {
  const classes = useStyles();

  return (
    <Login 
      {...props} 
      theme={loginTheme}
      >
      <CssBaseline />
      <img 
        src={require('../assets/logo.png').default} 
        alt="App do Ben"
        className={classes.logo} />
      <Typography 
        paragraph
        className={classes.text}>
        Seja bem-vindo(a) ao Painel do App do Ben.<br />Faça seu login.
      </Typography>
      <CustomLoginForm />
    </Login>
  );
}

export default withStyles(styles)(CustomLoginPage);
