import {
  Filter,
  Datagrid,
  EmailField,
  TextInput,
  TextField,
  BooleanInput
} from 'react-admin';
import { activeRowStyle, CustomList, StatusField } from '../../custom';

function Filters(props) {
  return (
    <Filter {...props}>
      <TextInput fullWidth label="Buscar por nome" source="name[$regex]" />
      <TextInput fullWidth label="Buscar por email" source="email[$regex]" />
      <BooleanInput label="Ativo" source="active" />
    </Filter>
  );
}

export default function ListAdminUsers(props) {
  return (
    <CustomList
      {...props}
      filters={<Filters />}
      title={props.options.label}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid rowStyle={activeRowStyle} rowClick="edit">
        <TextField label="Nome" source="name" />
        <EmailField label="E-mail" source="email" />
        <StatusField label="Ativo" />
      </Datagrid>
    </CustomList>
  );
}
