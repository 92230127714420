import UploadIcon from '@material-ui/icons/Publish';
import { cloneElement } from 'react';
import {
  BooleanInput,
  Button,
  CreateButton,
  Datagrid,
  EmailField,
  Filter,
  Link,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar
} from 'react-admin';
import { CustomList, StatusField, activeRowStyle } from '../../custom';

function ImportButton(props) {
  return (
    <Button component={Link} to="/import-users" label="Importar voluntários">
      <UploadIcon />
    </Button>
  );
}

function ListCompanyUsersActions(props) {
  return (
    <TopToolbar>
      {cloneElement(props.filters, { context: 'button' })}
      <CreateButton />
      <ImportButton />
    </TopToolbar>
  );
}

function ListCompanyUsersFilters(props) {
  return (
    <Filter {...props}>
      <TextInput fullWidth label="Buscar por nome" source="name[$regex]" />
      <BooleanInput label="Conta deletada" source="deleted" />
      <BooleanInput label="Ativo" source="active" />
      <BooleanInput label="Administrador" source="isAdmin" />
      <BooleanInput label="Líder" source="isLeader" />
      <BooleanInput label="Aceitou termos App" source="acceptedTermsApp" />
    </Filter>
  );
}

export default function ListCompanyUsers(props) {
  return (
    <CustomList
      {...props}
      actions={<ListCompanyUsersActions />}
      bulkActionButtons={false}
      filters={<ListCompanyUsersFilters />}
      title={props.options.label}
      sort={{ field: 'team', order: 'ASC' }}
    >
      <Datagrid rowStyle={activeRowStyle} rowClick="edit">
        <TextField label="Nome" source="name" />

        <EmailField label="E-mail" source="email" />

        <ReferenceField source="team" reference="teams" label="Equipe">
          <TextField source="name" />
        </ReferenceField>

        <StatusField
          label="Conta deletada"
          source="deleted"
          values={['Sim', 'Não']}
          sortable={false}
          textAlign="center"
        />

        <StatusField
          label="Líder"
          source="isLeader"
          values={['Sim', 'Não']}
          sortable={false}
          textAlign="center"
        />

        <StatusField
          label="Admin"
          source="isAdmin"
          values={['Sim', 'Não']}
          sortable={false}
          textAlign="center"
        />

        <StatusField
          align="center"
          label="Status"
          textAlign="center"
          sortable={false}
        />

        <StatusField
          label="Aceitou termos App"
          source="acceptedTermsApp"
          values={['Sim', 'Não']}
          textAlign="center"
        />
      </Datagrid>
    </CustomList>
  );
}
