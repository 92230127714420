import icon from '@material-ui/icons/Description';
import create from './create';
import edit from './edit';
import list from './list';

export { create, edit, list };

const companyPosts = {
  name: 'company-posts',
  options: { label: 'Notícias da empresa' },
  icon,
  create,
  edit,
  list
};

export default companyPosts;
