import {
  BooleanInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required
} from 'react-admin';
import { CustomCreate } from '../../custom';

export default function CreateNotifications(props) {
  return (
    <CustomCreate {...props} title="Novo notificação">
      <SimpleForm
        {...props}
        redirect="list"
        initialValues={{ type: 'ADMIN_NOTIFICATION', recipientType: 'ALL' }}
      >
        <TextInput
          fullWidth
          label="Título"
          source="title"
          validate={[required()]}
        />

        <TextInput
          fullWidth
          label="Mensagem"
          source="body"
          validate={[required()]}
        />

        <BooleanInput source="notifyCompany" label="Notificar empresa" />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.notifyCompany && (
              <ReferenceInput
                label="Empresa"
                source="company"
                reference="companies"
                sort={{ field: 'name', order: 'ASC' }}
                filter={{
                  $limit: -1
                }}
                validate={[required()]}
                {...rest}
              >
                <SelectInput />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </CustomCreate>
  );
}
