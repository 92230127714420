import icon from '@material-ui/icons/Person';
import list from './list';

export { list };

const registrations = {
  name: 'registrations',
  options: { label: 'Aprovações de cadastros' },
  icon,
  list
};

export default registrations;
