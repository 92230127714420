import { Link } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { useRecordContext } from "react-admin";
const { REACT_APP_WEB_URL } = process.env;

const CustomUrlField = ({ source }: { source: string }) => {
    const record = useRecordContext();
    const formLink = `${REACT_APP_WEB_URL}/${record[source]}/cadastro`;
    return record ? (
        <Link
          href={formLink} 
          target="_blank" 
          onClick={(e) => e.stopPropagation()}
          sx={{ textDecoration: "none" }}
        >
            {formLink}
            <LaunchIcon sx={{ fontSize: 15, ml: 1 }} />
        </Link>
    ) : null;
};

export default CustomUrlField;