import icon from '@material-ui/icons/EmojiPeople';
import list from './list';
import show from './show';

export { list, show };

const activities = {
  name: 'activities',
  options: { label: 'Ações' },
  icon,
  list,
  show
};

export default activities;
