import icon from '@material-ui/icons/Business';
import list from './list';

export { list };

const company = {
  name: 'company',
  options: { label: 'Editar empresa' },
  icon,
  list
};

export default company;
