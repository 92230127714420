import { Edit } from 'react-admin';
import { formWrapper } from '.';
import AdminTitle from './AdminTitle';

export default function CustomEdit(props) {
  return (
    <>
      <AdminTitle />
      {formWrapper({ ...props, mutationMode: 'pessimistic' })(Edit)}
    </>
  );
}
