import { CustomCreate } from '../../custom';
import Form from './form';

export default function CreatePublicPosts(props) {
  return (
    <CustomCreate {...props} title="Nova notícia">
      <Form />
    </CustomCreate>
  );
}
