import { CustomEdit } from '../../custom';
import Form from './form';

export default function EditCompanyUser(props) {
  return (
    <CustomEdit {...props} title="Editar colaborador">
      <Form />
    </CustomEdit>
  );
}
