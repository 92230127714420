import feathers from '@feathersjs/client';
import { Admin, Resource } from 'react-admin';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { resources } from './components';
import config from './config';
import { Layout, Login } from './layout';
import { authProvider, dataProvider, i18nProvider } from './providers';

const app = feathers();
const restClient = feathers.rest(config.API);
app.configure(restClient.fetch(window.fetch));
app.configure(feathers.authentication());

const appProps = {
  authProvider: authProvider(app),
  dataProvider: dataProvider(app),
  i18nProvider,
  loginPage: Login,
  layout: Layout
};

const {
  activities,
  adminUsers,
  companies,
  company,
  companyReports,
  companyUsers,
  importUsers,
  notifications,
  publicPosts,
  companyPosts,
  reports,
  settings,
  teams,
  activityCategories,
  registrations,
  salaryOptions,
  passwordResets
} = resources;

export { app };

export default function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <Admin {...appProps}>
        {permissions => [
          ...Object.entries({
            adminUsers,
            companies,
            notifications,
            publicPosts,
            activityCategories,
            reports,
            settings,
            salaryOptions
          }).map(
            ([key, resource]) =>
              permissions.includes('SUPERADMIN') && (
                <Resource {...resource} key={`superadmin-${key}`} />
              )
          ),
          ...Object.entries({
            activities,
            company,
            companyReports,
            companyUsers,
            importUsers,
            companyPosts,
            passwordResets,
            registrations,
            teams
          }).map(
            ([key, resource]) =>
              permissions.includes('ADMIN') && (
                <Resource {...resource} key={`admin-${key}`} />
              )
          ),
          <Resource key="reports-exports" name="reports-exports" />,
          <Resource key="states" name="states" />
        ]}
      </Admin>
    </DndProvider>
  );
}
